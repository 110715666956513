/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FC, Fragment, MouseEvent, ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, IconButton } from '@mui/material';
import { LoginStep, showLogoutModalAtom } from '../../../../shared/states/login';
import { businessProfileAtom } from '../../../../shared/states/business-profile';
import { BLACK, BORDER_COLOR, SIDEBAR_WIDTH } from '../../../../shared/typings';
import CopyIcon from '../../../../shared/icons/Copy';
import { ROUTES, RoutePages } from '../../../../shared/constants/routes';
import GroupsOutlinedIcon from '../../../../shared/icons/GroupsOutlined';
import HomeIcon from '../../../../shared/icons/Home';
import LogoutIcon from '../../../../shared/icons/Logout';
import CustomersIcon from '../../../../shared/icons/Customers';
import AnalyticsIcon from '../../../../shared/icons/Analytics';
import ReportsIcon from '../../../../shared/icons/Reports';
import WalletIcon from '../../../../shared/icons/Wallet';
import Wallet from '../../../../shared/components/molecules/sidebar-wallet';
import { AllUserPermissions } from '../../../../shared/typings/user';
import { ChatType } from '../../../home/v1/components/chat-list-section/chat-row/typings';
import SidebarMenuItem from '../../../../shared/components/molecules/sidebar-menu-item';
import { trackSelectOrg, trackSidebarOpen, trackSidebarClose } from '../../../../infra/analytics/utils';
import appConfig from '../../../../shared/config/app';
import RequestsIcon from '../../../../shared/icons/RequestsIcon';
import { Integration, IntegrationType, IntegrationStatus } from '../../../integrations/typings';
import useQuery from '../../../../shared/hooks/useQuery';
import { getAllIntegrationsAtom } from '../../../integrations/state';
import { getAllIntegrations } from '../../../integrations/requests';
import {
  useOrganizations,
  useSelectedOrganization,
  useSetSelectedOrganization,
} from '../../../../shared/hooks/use-organizations';
import { QSTypography } from '../../../../shared/components/atoms';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckIcon from '@mui/icons-material/Check';
import Loader from '../../../../shared/components/molecules/loader';
import ConfirmationModal from '../../../../shared/components/atoms/confirmation-modal';
import { ChatProfileAvatar } from '../../../home/v1/atoms/chat-profile-avatar';
import chooseOrganisation from '../../../login/v1/requests/choose-organisation';
import getUserDetails from '../../../home/v1/requests/get-user-details';
import getBusinessProfileDetails from '../../../../shared/requests/business-profile';
import useNotifications from '../../../../shared/hooks/use-notifications';
import { useUserPermissions } from '../../../../shared/hooks/use-permission';
import { firebaseSignInWithCustomToken } from '../../../../infra/auth/firebase-auth';
import { useProfile, useSetProfile } from '../../../../shared/hooks/use-profile';
import { useSetAuth } from '../../../../shared/hooks/use-auth';
import { LoginStepAtom } from '../../../login/v1/state';
import { openPopup } from '../../../../shared/utils/popup';
import copyToClipboard from '../../../../shared/utils/copy-to-clipboard';
import { BusinessProfileDetails } from '../../../../shared/typings/business-profile';
import { TextEllipsisStyles, WordWrapStyles } from '../../../../shared/components/emotion';
import InboxIcon from '../../../../shared/icons/Inbox';
import { useLocation } from 'react-router-dom';
import { shouldHideDTDetails } from '../../../login/v1/utility';
import { GetEnterpriseAnalyticsEmbedLinkResponse } from '../../typings';
import { enterpriseAnalyticsDetailsAtom } from '../../../../shared/states/enterprise-analytics';
import getEnterpriseAnalyticsEmbedLink from '../../requests/get-enterprise-analytics-embed-link';
import TemplateAttachmentSmall from '../../../../shared/icons/TemplateAttachmentSmall';
import ScheduleIconSmall from '../../../../shared/icons/ScheduleSmall';
import GroupIconSmall from '../../../../shared/icons/GroupSmall';
import BotsIconSmall from '../../../../shared/icons/BotSmall';
import AIBotIcon from '../../../../shared/icons/AIBotIcon';
import SettingsIconSmall from '../../../../shared/icons/SettingsIconSmall';
import { isEmbedAtom } from '../../../embed/state';
import LogoutModal from '../../../../shared/components/organisms/logout-modal';
import clearAllNotifications from '../../../login/v1/use-cases/clear-notifications';
import useIsMMTOrg from '../../../../shared/hooks/use-is-mmt-org';
import MetaIconSmall from '../../../../shared/icons/MetaIconSmall';

type SideBarItemsType = {
  icon: FC<any>;
  title: ReactNode;
  label?: string;
  path?: string;
  screenName?: RoutePages;
  onClick?: (event: MouseEvent) => void;
  hasAccess?: boolean;
  style?: SerializedStyles;
  analyticsEvent?: string;
  component?: () => void;
  disable?: boolean;
  hideRipple?: boolean;
};

const getSidebarItems = ({
  onLogout,
  getWalletComponent,
  permissions,
  canOpenPendingRequests,
  showOrgsDropdown,
  showChatGPTOption,
  showAnalyticsOption,
  showReportsOption,
  showEnterpriseAnalyticsOption,
  enterpriseAnalyticsTitle,
  isEmbed,
}: {
  onLogout: (event: MouseEvent) => void;
  getWalletComponent: () => void;
  permissions: AllUserPermissions;
  canOpenPendingRequests: boolean;
  showOrgsDropdown: boolean;
  showChatGPTOption?: boolean;
  showAnalyticsOption?: boolean;
  showReportsOption?: boolean;
  showEnterpriseAnalyticsOption?: boolean;
  enterpriseAnalyticsTitle?: string;
  isEmbed?: boolean;
}) => {
  const hideDTDetails = shouldHideDTDetails();

  if (isEmbed) {
    return {
      items: [] as SideBarItemsType[],
      secondaryItems: [
        {
          icon: LogoutIcon,
          onClick: onLogout,
          title: 'Logout',
          analyticsEvent: 'drawer_logout_clicked',
          style: css({
            marginBottom: 0,
            ...(!permissions.canManageWallet.hasAccess ? { marginTop: 'auto' } : {}),
          }),
        },
      ] as SideBarItemsType[],
    };
  }

  const items: SideBarItemsType[] = [
    {
      icon: HomeIcon,
      path: ROUTES.DASHBOARD,
      title: 'Home',
      screenName: RoutePages.DASHBOARD,
    },
    {
      icon: InboxIcon,
      path: ROUTES.CONVERSATIONS,
      title: 'Chats',
      analyticsEvent: 'drawer_template_clicked',
      screenName: RoutePages.CONVERSATIONS,
    },
    {
      icon: GroupsOutlinedIcon,
      path: ROUTES.CHANNEL_MANAGEMENT,
      title: 'Broadcast List',
      analyticsEvent: 'drawer_broadcast_channel_clicked',
      screenName: RoutePages.CHANNEL_MANAGEMENT,
      hasAccess: permissions.canManageChannels.hasAccess || permissions.canManageCreatedChannelAccess.hasAccess,
    },
    {
      icon: TemplateAttachmentSmall,
      path: ROUTES.TEMPLATES,
      title: 'Templates',
      analyticsEvent: 'drawer_template_clicked',
      hasAccess: permissions.canReadTemplates.hasAccess,
      screenName: RoutePages.TEMPLATES,
    },
    {
      icon: ScheduleIconSmall,
      path: ROUTES.SCHEDULED_BROADCASTS,
      title: 'Scheduled Broadcasts',
      analyticsEvent: 'drawer_scheduled_broadcasts_clicked',
      screenName: RoutePages.SCHEDULED_BROADCASTS,
    },
    {
      icon: CustomersIcon,
      path: ROUTES.CUSTOMERS,
      title: 'Customers',
      analyticsEvent: 'drawer_customers_clicked',
      screenName: RoutePages.CUSTOMERS,
      hasAccess: permissions.canGetCustomers.hasAccess,
    },
    {
      icon: AnalyticsIcon,
      path: ROUTES.ANALYTICS,
      title: (
        <Box
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 12px;
          `}
        >
          <Box
            css={css`
              color: inherit;
              background-color: transparent;
            `}
          >
            Analytics
          </Box>
          <Box
            css={css`
              font-size: 10px;
              font-weight: 600;
              line-height: 1.5;
              color: #ffffff;
              background-color: #adadad;
              padding: 4px 8px;
              border-radius: 6px;
            `}
          >
            BETA
          </Box>
        </Box>
      ),
      analyticsEvent: 'drawer_analytics_clicked',
      screenName: RoutePages.ANALYTICS,
      hasAccess: permissions.canGetAnalyticsWidgets.hasAccess,
      disable: !showAnalyticsOption,
    },
    {
      icon: AnalyticsIcon,
      path: ROUTES.ENTERPRISE_ANALYTICS,
      title: enterpriseAnalyticsTitle ?? 'Enterprise Analytcis',
      analyticsEvent: 'drawer_enterprise_analytics_clicked',
      screenName: RoutePages.ENTERPRISE_ANALYTICS,
      hasAccess: permissions.canGetAnalyticsWidgets.hasAccess,
      disable: !showEnterpriseAnalyticsOption,
    },
    {
      icon: ReportsIcon,
      path: ROUTES.REPORTS,
      title: 'Reports',
      analyticsEvent: 'drawer_reports_clicked',
      screenName: RoutePages.REPORTS,
      hasAccess: permissions.canGetReports.hasAccess,
      disable: !showReportsOption,
    },
    {
      icon: GroupIconSmall,
      path: ROUTES.TEAM_MANAGEMENT,
      title: 'Team',
      analyticsEvent: 'drawer_team_clicked',
      hasAccess: permissions.canReadMembers.hasAccess,
      screenName: RoutePages.TEAM_MANAGEMENT,
    },
    {
      icon: BotsIconSmall,
      path: ROUTES.BOTS,
      title: 'Bot Studio',
      analyticsEvent: 'drawer_bot_clicked',
      hasAccess: permissions.canReadBots.hasAccess,
      screenName: RoutePages.BOTS,
    },
    {
      icon: AIBotIcon,
      path: ROUTES.AI_BOT,
      title: 'AI Bot',
      analyticsEvent: '',
      hasAccess: true,
      screenName: RoutePages.AI_BOT,
      disable: !showChatGPTOption,
    },
    {
      icon: RequestsIcon,
      path: ROUTES.REQUESTS,
      title: 'Pending requests',
      screenName: RoutePages.REQUESTS,
      hasAccess: canOpenPendingRequests && permissions.canManagePrivacyBotAccess.hasAccess,
      disable: !canOpenPendingRequests,
    },
    {
      icon: SettingsIconSmall,
      path: ROUTES.SETTINGS,
      title: 'Settings',
      analyticsEvent: 'drawer_settings_clicked',
      screenName: RoutePages.SETTINGS,
    },
  ];

  if (permissions.canAccessAdsInsights.hasAccess) {
    items.push({
      icon: MetaIconSmall,
      path: ROUTES.AD_INSIGHT,
      title: 'Ad Insights',
      screenName: RoutePages.AD_INSIGHT,
    });
  }

  const secondaryItems: SideBarItemsType[] = [
    {
      icon: WalletIcon,
      path: '',
      title: 'Wallet',
      component: getWalletComponent,
      disable: !permissions.canManageWallet.hasAccess,
      hideRipple: true,
    },
    {
      icon: LogoutIcon,
      onClick: onLogout,
      title: 'Logout',
      analyticsEvent: 'drawer_logout_clicked',
      style: css({
        marginBottom: 0,
        ...(!permissions.canManageWallet.hasAccess ? { marginTop: 'auto' } : {}),
      }),
    },
  ];

  if (showOrgsDropdown) {
    return { items: [], secondaryItems };
  }

  return {
    items: items.filter(({ disable }) => !disable),
    secondaryItems: secondaryItems.filter(({ disable }) => !disable),
  };
};

const Sidebar: FC<{ permissions: AllUserPermissions }> = memo(({ permissions }) => {
  const [showExpandedView, setShowExpandedView] = useState(false);
  const [showOrgsDropdown, setShowOrgsDropdown] = useState<boolean>(false);
  const { organizationState } = useOrganizations();
  const organisations = useMemo(() => organizationState?.organisations || [], [organizationState?.organisations]);
  const currentOrg = useSelectedOrganization();
  const isMMTOrg = useIsMMTOrg();
  const [switchOrgConfirmModal, setSwitchOrgConfirmModal] = useState<boolean>(false);
  const userDetails = useProfile();

  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const { addNotification } = useNotifications();
  const { canGetAnalyticsWidgets, canGetReports } = useUserPermissions();
  const setUserProfile = useSetProfile();
  const setSelectedOrganization = useSetSelectedOrganization();
  const setAuthState = useSetAuth();
  const setLoginStep = useSetAtom(LoginStepAtom);
  const location = useLocation();
  const isEmbed = useAtomValue(isEmbedAtom);
  const [showLogoutModal, setShowLogoutModal] = useAtom(showLogoutModalAtom);

  useEffect(() => {
    const refetchUserProfile = async () => {
      try {
        const profile = await getUserDetails(true);
        setUserProfile(profile);
      } catch (error) {}
    };

    refetchUserProfile();
  }, [setUserProfile]);

  const wabaPhoneNumber = !!currentOrg?.wabaPhoneNumber?.toString()
    ? currentOrg?.wabaPhoneNumber?.toString()?.replaceAll('+', '')
    : undefined;
  const sidebarOrganisationTitleHeight = 40;
  let sidebarWabaSectionHeight = !showOrgsDropdown && wabaPhoneNumber ? 66 : 0;

  const {
    requestData: { data, loading },
  } = useQuery<Integration[]>({
    requestAtom: getAllIntegrationsAtom,
    queryFunction: getAllIntegrations,
  });

  const {
    requestData: { data: businessDetails, loading: businessDetailsLoading },
  } = useQuery<BusinessProfileDetails | undefined>({
    requestAtom: businessProfileAtom,
    queryFunction: getBusinessProfileDetails,
  });

  const {
    requestData: { data: enterpriseAnalyticsDetails, loading: enterpriseAnalyticsDetailsLoading },
  } = useQuery<GetEnterpriseAnalyticsEmbedLinkResponse | undefined>({
    requestAtom: enterpriseAnalyticsDetailsAtom,
    queryFunction: getEnterpriseAnalyticsEmbedLink,
  });

  const showChatGPTOption = useMemo(() => {
    if (!loading && data) {
      return data.some(({ type, status }) => type === IntegrationType.OPENAI && status === IntegrationStatus.CONNECTED);
    }
    return false;
  }, [data, loading]);

  const showAnalyticsOption = useMemo(() => {
    if (!loading && !enterpriseAnalyticsDetailsLoading) {
      return (
        canGetAnalyticsWidgets.hasAccess &&
        (enterpriseAnalyticsDetails?.embedLink
          ? !enterpriseAnalyticsDetails.replaceAnalyticsWithEnterpriseAnalytics
          : true)
      );
    }
    return false;
  }, [loading, canGetAnalyticsWidgets, enterpriseAnalyticsDetailsLoading, enterpriseAnalyticsDetails]);

  const showEnterpriseAnalyticsOption = useMemo(() => {
    if (!loading && !enterpriseAnalyticsDetailsLoading) {
      return canGetAnalyticsWidgets.hasAccess && !!enterpriseAnalyticsDetails?.embedLink;
    }
    return false;
  }, [loading, canGetAnalyticsWidgets, enterpriseAnalyticsDetailsLoading, enterpriseAnalyticsDetails]);

  const showReportsOption = useMemo(() => {
    if (!loading) {
      return canGetReports.hasAccess;
    }
    return false;
  }, [loading, canGetReports]);

  const canOpenPendingRequests = useMemo(() => {
    if (!loading && data) {
      return data.some(
        ({ type, status }) => type === IntegrationType.QUICKSELL && status === IntegrationStatus.CONNECTED
      );
    }
    return false;
  }, [data, loading]);

  const handleMouseHoverIn = useCallback(() => {
    setShowExpandedView(true);
    trackSidebarOpen();
  }, []);

  const handleMouseHoverOut = useCallback(() => {
    setShowExpandedView(false);
    setShowOrgsDropdown(false);
    trackSidebarClose();
  }, []);

  const walletComponentRenderer = useCallback(() => <Wallet showExpandedView={showExpandedView} />, [showExpandedView]);

  const handleOrgClick = (orgId: string) => {
    setSelectedOrgId(orgId);
    setSwitchOrgConfirmModal(true);
  };

  const handleSwitchOrgClick = async () => {
    try {
      const selectedOrg = organizationState?.organisations.find(
        ({ orgId }: { orgId: string }) => selectedOrgId === orgId
      );
      if (!selectedOrg) {
        addNotification({
          message: 'Selected organization absent!',
          type: 'error',
        });
        return;
      }
      const { user } = await chooseOrganisation(selectedOrgId);
      trackSelectOrg();
      await firebaseSignInWithCustomToken(user.token);
      clearAllNotifications();
      const profile = await getUserDetails(true);
      setUserProfile(profile);
      setSelectedOrganization(selectedOrg);
      setAuthState({
        isAuthReady: true,
        isCheckingAuth: false,
        isOrgSelected: !!selectedOrg,
        isIntegrationEnabled: true,
        isPermissionsReady: true,
        makePayment: false,
      });
      setLoginStep(LoginStep.PHONE);
      window.location.replace(location.pathname);
    } catch (error) {
      addNotification({
        message: (error as Error).message ?? 'Error while selecting organization',
        type: 'error',
      });
    } finally {
      setSwitchOrgConfirmModal(false);
    }
  };

  const handleWhatsappRouteClick = async () => {
    try {
      openPopup(`https://wa.me/+${wabaPhoneNumber}`);
    } catch (error) {
      addNotification({
        message: 'Error sending WhatsApp message',
        type: 'error',
      });
    }
  };

  const { items, secondaryItems } = getSidebarItems({
    onLogout: () => setShowLogoutModal(true),
    getWalletComponent: walletComponentRenderer,
    permissions,
    canOpenPendingRequests,
    showOrgsDropdown,
    showChatGPTOption,
    showAnalyticsOption,
    showReportsOption,
    showEnterpriseAnalyticsOption,
    enterpriseAnalyticsTitle: enterpriseAnalyticsDetails?.title,
    isEmbed,
  });

  // hardcoding some logic for MMT
  const isMMT = isMMTOrg();

  return (
    <div
      css={css`
        background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
        border-right: 1px solid ${BORDER_COLOR};
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 3;
        left: 0;
        width: ${showExpandedView ? '230px' : `${SIDEBAR_WIDTH}px`};
        min-width: ${showExpandedView ? '230px' : `${SIDEBAR_WIDTH}px`};
        max-width: ${showExpandedView ? '230px' : `${SIDEBAR_WIDTH}px`};
        transition: width 280ms ease-in-out, min-width 280ms ease-in-out, max-width 280ms ease-in-out;
        align-items: center;
        justify-content: flex-start;
      `}
      onMouseEnter={handleMouseHoverIn}
      onMouseLeave={handleMouseHoverOut}
    >
      <Box
        css={css`
          height: ${showOrgsDropdown
            ? 'auto'
            : `calc(${sidebarOrganisationTitleHeight}px + ${sidebarWabaSectionHeight}px)`};
          min-height: ${showOrgsDropdown
            ? 'auto'
            : `calc(${sidebarOrganisationTitleHeight}px + ${sidebarWabaSectionHeight}px)`};
          width: 100%;
          overflow: ${showExpandedView ? 'auto' : 'hidden'};
          transition: all 280ms ease-in-out;
          flex: 0 1 auto;
        `}
      >
        <ButtonBase<'div'>
          css={css`
            height: ${sidebarOrganisationTitleHeight}px;
            width: 100%;
            color: ${BLACK};
            display: flex;
            align-items: center;
            justify-content: ${showExpandedView ? 'space-between' : 'center'};
            text-align: left;
            margin: 0px;
            cursor: ${organisations && organisations.length > 1 && !isEmbed ? 'pointer' : 'default'};
            padding: ${showExpandedView ? '0px 8px 0px 16px' : '0px 8px'};
            ${organisations.length > 1 && !isEmbed
              ? `
                &:hover {
                  background: #ebebebde;
                }
              `
              : ''}
          `}
          onClick={() => {
            if (!organisations || organisations.length <= 1 || isEmbed) {
              return;
            }
            setShowOrgsDropdown((previous) => !previous);
          }}
          disabled={loading}
          disableRipple={!organisations || organisations.length <= 1 || isEmbed}
          component="div"
        >
          {showExpandedView ? (
            <Fragment>
              <QSTypography
                css={css`
                  font-weight: 600;
                  width: calc(100% - 18px);
                  padding-right: 12px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-wrap: break-word;
                  word-break: break-all;
                `}
              >
                {currentOrg?.name}
              </QSTypography>
              {organisations.length > 1 && !isEmbed && (
                <div
                  css={css`
                    width: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <ArrowDropDownIcon
                    css={css`
                      transform: rotateZ(${showOrgsDropdown ? 180 : 0}deg);
                      transition: transform 280ms ease-in-out;
                    `}
                  />
                </div>
              )}
            </Fragment>
          ) : businessDetailsLoading ? (
            <Box
              css={css`
                height: ${sidebarOrganisationTitleHeight - 12}px;
                min-height: ${sidebarOrganisationTitleHeight - 12}px;
                width: ${sidebarOrganisationTitleHeight - 12}px;
                min-width: ${sidebarOrganisationTitleHeight - 12}px;
                margin: 0px;
                padding: 0px;
              `}
            >
              <Loader size={sidebarOrganisationTitleHeight - 18} />
            </Box>
          ) : businessDetails?.logo && businessDetails?.logo.length > 0 ? (
            <img
              src={businessDetails?.logo}
              alt={currentOrg?.name ?? 'organisation profile logo'}
              css={css`
                height: ${sidebarOrganisationTitleHeight - 12}px;
                min-height: ${sidebarOrganisationTitleHeight - 12}px;
                width: 100%;
                min-width: 100%;
                margin: 0px;
                padding: 0px;
                object-fit: contain;
              `}
            />
          ) : (
            <ChatProfileAvatar
              chatType={ChatType.INDIVIDUAL}
              name={currentOrg?.name}
              phoneNumber={wabaPhoneNumber}
              styles={{
                height: sidebarOrganisationTitleHeight - 12,
                width: sidebarOrganisationTitleHeight - 12,
                margin: 0,
              }}
            />
          )}
        </ButtonBase>

        {!showOrgsDropdown && !!wabaPhoneNumber && wabaPhoneNumber.length > 0 && (
          <ButtonBase<'div'>
            css={css`
              width: 100%;
              height: ${sidebarWabaSectionHeight}px;
              margin: 0px;
              padding: ${showExpandedView ? '8px 10px 8px 16px' : '0px 8px'};
              display: flex;
              align-items: center;
              justify-content: ${showExpandedView ? 'space-between' : 'center'};
              text-align: left;
              border-top: 1px solid ${BORDER_COLOR};
              border-bottom: 1px solid ${BORDER_COLOR};
              &:hover {
                background: #ebebebde;
              }
            `}
            onClick={() => {
              if (isMMT) {
                return;
              }
              handleWhatsappRouteClick();
            }}
            component="div"
          >
            {showExpandedView ? (
              <Box
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  column-gap: 12px;
                `}
              >
                <Box
                  css={css`
                    width: calc(100% - calc(28px + 32px) - 12px);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    row-gap: 3px;
                  `}
                >
                  <QSTypography
                    css={css(
                      `
                            max-width: 100%;
                            font-size: 16px;
                            font-weight: 600;
                          `,
                      TextEllipsisStyles,
                      WordWrapStyles
                    )}
                  >
                    WA Number
                  </QSTypography>
                  <QSTypography
                    css={css(
                      `
                          max-width: 100%;
                          font-size: 16px;
                          font-weight: 400;
                        `,
                      TextEllipsisStyles,
                      WordWrapStyles
                    )}
                  >
                    {`+${wabaPhoneNumber}`}
                  </QSTypography>
                </Box>
                <Box
                  css={css`
                    width: ${isMMT ? '28px' : 'calc(28px + 32px)'};
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <IconButton
                    css={css`
                      height: 28px;
                      min-height: 28px;
                      max-height: 28px;
                      width: 28px;
                      min-width: 28px;
                      max-width: 28px;
                      padding: 4px;
                      border-radius: 50%;
                      background-color: transparent;
                      box-shadow: none;
                      outline: none;
                      border: none;
                    `}
                    onMouseDown={(event) => {
                      // Note: This is added to prevent nested button ripple effect
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      copyToClipboard(`+${wabaPhoneNumber}`);
                      addNotification({
                        message: 'WhatsApp number copied to clipboard',
                        type: 'success',
                      });
                    }}
                  >
                    <CopyIcon size={20} />
                  </IconButton>
                  {!isMMT && (
                    <IconButton
                      css={css`
                        height: 28px;
                        min-height: 28px;
                        max-height: 28px;
                        width: 32px;
                        min-width: 32px;
                        max-width: 32px;
                        padding: 4px;
                        border-radius: 50%;
                        background-color: transparent;
                        box-shadow: none;
                        outline: none;
                        border: none;
                      `}
                      onMouseDown={(event) => {
                        // Note: This is added to prevent nested button ripple effect
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleWhatsappRouteClick();
                      }}
                    >
                      <WhatsAppIcon
                        css={css`
                          height: 20px;
                          height: 20px;
                        `}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ) : isMMT ? (
              <QSTypography
                css={css`
                  font-size: 16px;
                  font-weight: 600;
                `}
              >
                WA
              </QSTypography>
            ) : (
              <WhatsAppIcon
                css={css`
                  height: 19px;
                  height: 19px;
                `}
              />
            )}
          </ButtonBase>
        )}

        {showOrgsDropdown &&
          organisations.length > 0 &&
          organisations.map((org, index) => {
            const orgWabaPhoneNumber = !!org.wabaPhoneNumber?.toString()
              ? org.wabaPhoneNumber?.toString()?.replaceAll('+', '')
              : undefined;
            return (
              <Box
                key={index}
                css={css`
                  padding: 4px 10px 4px 16px;
                  cursor: pointer;
                  border-top: ${index === 0 ? '1' : '0'}px solid ${BORDER_COLOR};
                  border-bottom: 1px solid ${BORDER_COLOR};
                  &:hover {
                    background: #ebebebde;
                  }
                `}
                onClick={() => handleOrgClick(org.orgId)}
              >
                <Box
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  `}
                >
                  <Box>
                    <QSTypography
                      css={css`
                        font-weight: 600;
                      `}
                    >
                      {org.name}
                    </QSTypography>
                    {!!orgWabaPhoneNumber && orgWabaPhoneNumber.length > 0 && (
                      <QSTypography>{`+${orgWabaPhoneNumber}`}</QSTypography>
                    )}
                  </Box>
                  {org.orgId === currentOrg?.orgId && (
                    <CheckIcon
                      css={css`
                        color: #3f906c;
                      `}
                    />
                  )}
                </Box>
              </Box>
            );
          })}
      </Box>

      <Box
        css={css`
          width: 100%;
          flex: 1 1 auto;
          overflow: auto;
          transition: all 280ms ease-in-out;
        `}
      >
        {items.map(
          (
            { icon, path, onClick, title, style, component, analyticsEvent, screenName, hasAccess, hideRipple },
            index
          ) => {
            return (
              <SidebarMenuItem
                key={index}
                Icon={icon}
                title={title}
                analyticsEvent={analyticsEvent}
                path={path}
                showExpandedView={showExpandedView}
                onClick={onClick}
                hasAccess={hasAccess}
                style={style}
                component={component}
                screenName={screenName}
                hideRipple={hideRipple}
              />
            );
          }
        )}
      </Box>

      <Box
        css={css`
          width: 100%;
          flex: 0 1 auto;
        `}
      >
        {secondaryItems.map(
          (
            { icon, path, onClick, title, style, component, analyticsEvent, screenName, hasAccess, hideRipple },
            index
          ) => {
            return (
              <SidebarMenuItem
                key={index}
                Icon={icon}
                title={title}
                analyticsEvent={analyticsEvent}
                path={path}
                showExpandedView={showExpandedView}
                onClick={onClick}
                hasAccess={hasAccess}
                style={style}
                component={component}
                screenName={screenName}
                hideRipple={hideRipple}
              />
            );
          }
        )}
      </Box>

      {appConfig?.appVersion && (
        <div
          css={css`
            padding: ${showExpandedView ? '8px 48px' : '8px 0px'};
            font-size: 12px;
            font-weight: 600;
            height: 34px;
            width: 100%;
            overflow: hidden;
            justify-content: flex-start;
            opacity: ${showExpandedView ? '1' : '0'};
            white-space: nowrap;
            flex: 0 1 auto;
            transition: all 280ms ease-in-out;
          `}
        >
          {`APP VERSION: ${appConfig?.appVersion}`}
        </div>
      )}

      <ConfirmationModal
        open={switchOrgConfirmModal}
        message="Are you sure you want to switch the organization?"
        onClick={handleSwitchOrgClick}
        onClose={() => setSwitchOrgConfirmModal(false)}
        okButtonText="Yes"
      />
      {isEmbed && <LogoutModal open={showLogoutModal} onClose={() => setShowLogoutModal(false)} />}
    </div>
  );
});

export default Sidebar;
